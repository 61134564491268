<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <!-- <b-card-sub-title class="mb-25">
          Balance
        </b-card-sub-title> -->
        <b-card-title>{{ $t('ActualVsTheoreticalProgress') }}</b-card-title>
      </div>
      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-horizontal-bar-chart
        :height="283"
        :data="this.series"
        :options="horizontalBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentHorizontalBarChart from '@/views/habit/charts-components/ChartjsComponentHorizontalBarChart.vue'
import { $themeColors } from '@themeConfig'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    ChartjsComponentHorizontalBarChart,
  },
  props: {
    progressData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      horizontalBarChart: {
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            mode: "point",
            callbacks: {
              title: function(tooltipItems, data) {
                return ''
              },
              label: function(tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || ''
                let newLabel = ''
                if (label) label += ': '
                let valueToDisplay = tooltipItem.datasetIndex === 1
                  ? tooltipItem.xLabel + data.datasets[0].data[0]
                  : tooltipItem.xLabel
                if (data.datasets[tooltipItem.datasetIndex].totalDone && data.datasets[1].data[0] !== 0){
                  newLabel = label + valueToDisplay + '% / ' + i18n.t('Observadas') + ': ' + data.datasets[tooltipItem.datasetIndex].totalDone
                  return newLabel
                }
                if (data.datasets[1].data[0] === 0) {   // Actual === Theoretical progress
                  return [
                    label + valueToDisplay + '% / ' + i18n.t('Observadas') + ': ' + data.datasets[tooltipItem.datasetIndex].totalDone,
                    data.datasets[1].label + ': ' + valueToDisplay + '%' 
                  ]
                }
                return label + valueToDisplay + '%'
                
              }
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            // display: false,
            position: 'top',
            align: 'center',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
            // Override default behaviour for legend onClick
            onClick: function(e, legendItem) {}
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                  borderColor: 'transparent',
                  color: 'rgba(200, 200, 200, 0.2)',
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  // max: 100,
                  stepSize: 10,
                  fontColor: '#6e6b7b',
                  precision: 0,
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
          },
        },
        // data: {
        //   labels: ['MON', 'TUE', 'WED ', 'THU', 'FRI', 'SAT', 'SUN'],
        //   datasets: [
        //     {
        //       data: [710, 350, 470, 580, 230, 460, 120],
        //       backgroundColor: $themeColors.info,
        //       borderColor: 'transparent',
        //       barThickness: 15,
        //     },
        //   ],
        // },
      },
      // rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
  computed: {
    series() {
      let actualProgress = this.progressData.actual
      let theoreticalProgress = this.progressData.theoretical
      let totalDone = this.progressData.totalDone
      if (theoreticalProgress < actualProgress) {
        actualProgress -= theoreticalProgress
        return {
          datasets: [
            {
              label: i18n.t('TheoreticalProgress'),
              data: [theoreticalProgress],
              backgroundColor: $themeColors.info,
              borderColor: 'transparent',
              barThickness: 30,
            },
            {
              label: i18n.t('ActualProgress'),
              data: [actualProgress],
              backgroundColor: $themeColors.success,
              borderColor: 'transparent',
              barThickness: 30,
              totalDone: totalDone
            },
          ],
        }
      }
      theoreticalProgress -= actualProgress
      return {
        datasets: [
          {
            label: i18n.t('ActualProgress'),
            data: [actualProgress],
            backgroundColor: $themeColors.info,
            borderColor: 'transparent',
            barThickness: 30,
            totalDone: totalDone
          },
          {
            label: i18n.t('TheoreticalProgress'),
            data: [theoreticalProgress],
            backgroundColor: theoreticalProgress === 0 ? $themeColors.info : $themeColors.danger,
            borderColor: 'transparent',
            barThickness: 30,
          },
        ],
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
