<template>
  <b-card no-body>
    <b-card-header>
      <!-- title -->
      <b-card-title>{{ $t('confirmationsObservedPerSupervisorAndPerProcess') }}</b-card-title>

      <!-- Explanation for the difference between the total confirmations and the total shown in the chart -->
      <b-col v-if="confirmationTotal !== heatMapTotalValue" md="12 pl-0 mt-1">
        <span>{{ $t('message.totalConfirmations') }}: {{ confirmationTotal }}</span>
        <span class="mx-1">-</span>
        <span>{{ $t('message.topSupervisorsConfirmed') }}: {{ heatMapTotalValue }}</span>
      </b-col>

      <!-- dropdown -->
      <!-- <b-dropdown
        variant="link"
        toggle-class="p-0"
        no-caret
      >
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="MoreVerticalIcon"
          />
        </template>
        <b-dropdown-item>
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item>
          Last Month
        </b-dropdown-item>
        <b-dropdown-item>
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
      <!--/ dropdown -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        ref="heatmapChart"
        type="heatmap"
        :height="this.height"
        :options="heatMapChart.options"
        :series="this.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle, BDropdown, BDropdownItem } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { EventBus } from "@/views/habit/EventBus.js"
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    VueApexCharts,
    BCardTitle,
    BDropdown,
    BDropdownItem,
  },
  props: {
    confirmationsPerDayData: {
      type: Array,
      required: true,
    },
    confirmationTotal: {
      Type: Number,
      required: true
    },
    xLabelColors: {
      type: Array,
      required: true,
    },
    yLabelColors: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      heatMapChart: {
        options: {
          tooltip: {
            followCursor: false,
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              return (
                `
                  <div style="padding: 10px; display:${w.globals.seriesNames[seriesIndex] === i18n.t('tooltip.total_per_process') || w.globals.labels[dataPointIndex] === i18n.t('tooltip.total_per_supervisor') ? 'none' : 'block'}">
                    <div>
                      <span>${i18n.t('tooltip.supervisor')}: </span>
                      <span style="font-weight: bold">${w.globals.seriesNames[seriesIndex]}</span>
                    </div>
                    <div>
                      <span>${i18n.t('tooltip.process')}: </span>
                      <span style="font-weight: bold">${w.globals.labels[dataPointIndex]}</span>
                    </div>
                    <div>
                      <span>${i18n.t('tooltip.observed')}: </span>
                      <span style="font-weight: bold">${series[seriesIndex][dataPointIndex]}</span>
                    </div>
                  </div>
 
                  <div style="padding: 10px; display:${w.globals.seriesNames[seriesIndex] === i18n.t('tooltip.total_per_process') || w.globals.labels[dataPointIndex] === i18n.t('tooltip.total_per_supervisor') ? 'block' : 'none'}">
                    <div style="display:${w.globals.seriesNames[seriesIndex] === i18n.t('tooltip.total_per_process') &&  w.globals.labels[dataPointIndex] === i18n.t('tooltip.total_per_supervisor') ? 'none' : 'block'}">
                      <span>${w.globals.seriesNames[seriesIndex] === i18n.t('tooltip.total_per_process') ? i18n.t('tooltip.process'): i18n.t('tooltip.supervisor')}:
                      </span>
                      <span style="font-weight:bold">${w.globals.labels[dataPointIndex] === i18n.t('tooltip.total_per_supervisor') ? w.globals.seriesNames[seriesIndex] : w.globals.labels[dataPointIndex]}
                      </span>    
                    </div>                
                    <div>
                      <span>Total:</span>
                      <span style="font-weight: bold">${series[seriesIndex][dataPointIndex]}</span>
                    </div>
                  </div>
                `
              )
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#ffffff']
            }
          },
          legend: {
            show: false,
          },
          stroke: {
            width: 1,
            colors: ['#d3d3d3']
          },
          plotOptions: {
            heatmap: {
              enableShades: false,
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 0,
                    color: '#ffffff',
                  },
                  {
                    from: 1,
                    to: 10000,
                    color: '#c1c1c1',
                  },
                ],
              },
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: this.xLabelColors,
              }
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: this.yLabelColors,
              }
            },
          },
          chart: {
            height: 350,
            type: 'heatmap',
            toolbar: {
              show: false,
            },
            events: {
              click: function(event, chartContext, config) {
                if (config.seriesIndex < 0 || config.dataPointIndex < 0) return
                const supervisorId = config.config.series[config.seriesIndex].supervisorId
                const processId = config.config.series[config.seriesIndex].data[config.dataPointIndex].processId
                EventBus.$emit("filterBySupervisorAndProcess", supervisorId, processId)
              },
              dataPointMouseEnter: function(event) {
                event.srcElement.style.cursor = "pointer"
              }
            }
          },
        },
      },
      heatMapTotalValue: 0,
    }
  },
  computed: {
    height() {
      return 150 + 20 * this.confirmationsPerDayData.length
    },
    series() {
      return this.confirmationsPerDayData.slice(0, 50)
    },
  },
  mounted() {
    // Accessing all <rect> elements in the last row and last column
    const lastRowCells = this.$refs.heatmapChart.$el.querySelectorAll('.apexcharts-heatmap-rect[i="0"]')
    const lastColumnCells = this.$refs.heatmapChart.$el.querySelectorAll(`.apexcharts-heatmap-rect[j="${this.xLabelColors.length - 1}"]`)

    // Change the fill color of each rect in the last row and column
    lastRowCells.forEach(cell => cell?.setAttribute('fill', '#56616d'))
    lastColumnCells.forEach(cell => cell?.setAttribute('fill', '#56616d'))
    
    // Accessing the last cell of the row and column
    const lastRowLastCell = lastRowCells[lastRowCells.length - 1]
    const lastColumnLastCell = lastColumnCells[lastColumnCells.length - 1]
    
    // Change the fill color of the last cell
    lastRowLastCell?.setAttribute('fill', '#434d56')
    lastColumnLastCell?.setAttribute('fill', '#434d56')
    
    const heatMapTotal = this.confirmationsPerDayData.slice(0, 50)
    this.heatMapTotalValue = heatMapTotal[0]?.data[heatMapTotal[0]?.data?.length - 1]?.y || 0
  }
}
</script>