<template lang="pug">
.dashboard-wrapper
  dashboard-fixed(
    v-if="fixedCalculation || fixedCalculationByRole"
    :fixedCalculation="fixedCalculation"
    )
  dashboard-default(v-else)
</template>

<script>
import DashboardFixed from "./dashboards/DashboardFixed.vue";
import DashboardDefault from "./dashboards/DashboardDefault.vue";

export default {
  components: {
    DashboardFixed,
    DashboardDefault,
  },
  setup() {
    const fixedCalculation = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).compliance_calculation_fixed
      : false
    
    const fixedCalculationByRole = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).compliance_calculation_by_role
      : false

    return {
      fixedCalculation,
      fixedCalculationByRole
    };
  },
};
</script>
